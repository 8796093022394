import logo from './logo.svg';
import './App.css';
import Car from './components/car.js'
import { useEffect,useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout.js";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import NoPage from './pages/NoPage.js';

export default function App() {
  const [clickEvent, setClickEvent] = useState(false);

  const handleClickEvent = () => {
    setClickEvent(true);
  };

  const [value, setValue] = useState('');

  const handleSendValue = (newValue) => {
    setValue(newValue);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout onSendValue={handleSendValue}/>}>
          <Route index element={<Home value={value} />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

{
  console.log("Home Cherry pick test")
}
{
  console.log("Home Cherry pick test again")
}
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//         <Car brand="Ford"/>
//       </header>
//     </div>
//   );
// }

// export default App;

import React, { createContext, useState, useEffect } from "react";

const Contact = () => {
   useEffect(()=>{
    const saved = localStorage.getItem("token");
    const token = JSON.parse(saved);
    alert(token)
   },[])
    return <div>
      <h1>Contact Me</h1>
      <p>Contact Me Cherry pick</p>
    </div>;
  };
  
  export default Contact;
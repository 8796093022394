import { Outlet, Link, useNavigate } from "react-router-dom";
import { useEffect,useState } from "react";

const Layout = ({ onSendValue }) => {
  let navigate = useNavigate();
  const [clickIndex, setClickIndex]=useState(0)
  const handleClick = () => {
    const newValue = "Hello from Layout"; // Example value to send
    onSendValue(newValue);
  };
  useEffect(()=>{
   
    
   },[])

  return (
    <>
      <nav>
        <div
          style={{
            backgroundColor: "black",
            display: "flex",
            height: 70,
            justifyContent: "center",
            //alignItems: "flex-end",
            flex: 1,
            flexDirection: "row"
          }}>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flex: 2,
            }}>
            <h1 style={{ color: 'white', marginLeft: 20,cursor: 'pointer',marginLeft:100 }}>RKS MOBILE</h1>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              flexDirection: "row",
              fontSize: 16,
              marginRight:200
            }}>

            <div style={{ flex: 1, display: "flex", }} >
              <Link style={{color:'white',textDecoration: 'none'  }} to="/">Home</Link>
            </div>
            <div
              style={{ flex: 1, display: "flex" }}
            >
            
              <Link style={{color:'white',textDecoration: 'none'  }} onClick={() =>{
                 onSendValue('aboutus');
              }} >About Us</Link>
            </div>
            <div
              style={{ flex: 1, display: "flex", }}
            >
              <Link style={{color:'white',textDecoration: 'none'  }} onClick={() =>{
                 onSendValue('blog');
              }}>Apps</Link>
            </div>
            <div
              style={{ flex: 1, display: "flex", }}
            >
              <Link style={{color:'white',textDecoration: 'none'  }} onClick={() =>{
                onSendValue('contact');
              }}>Contact</Link>
            </div>

          </div>

        </div>
      </nav>

      <Outlet />
    </>
  );
};

export default Layout;

import { useNavigate, useLocation } from "react-router-dom";
import { UserDataContext } from "../context/UserContext";
import { useContext } from "react";

const Blogs = () => {
  const location = useLocation();
  const {getData}= useContext(UserDataContext)
  let navigate = useNavigate();
  return (
    <div>
      <h1>Blog Articles</h1>
      <p>Blog Cherry pick</p>
      <p>{getData?.name} --- - {getData?.age}</p>
      <h1>{location?.state?.name}</h1>
      <button onClick={() => navigate(-1)}>Back</button>
    </div>
  );
};

export default Blogs;

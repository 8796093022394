
import { Outlet, Link, useNavigate } from "react-router-dom";
import "./Home.css"
import heroBg from "./assets/hero-bg.jpg"
import mobileApp from "./assets/Mobile-App.png"
import features from "./assets/features-4.png"
import applelogo from "./assets/apple-logo.png"
import www from "./assets/www.png"
import service from "./assets/service.png"
import customer from "./assets/customer.png"
import email from "./assets/email.png"
import next from "./assets/next.png"
import call from "./assets/call.png"
import location from "./assets/location.png"
import { UserDataContext } from "../context/UserContext";
import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const Home = ({ value }) => {
  let navigate = useNavigate();
  // const testUserData = useContext(UserDataContext)
  const { getData, setData } = useContext(UserDataContext)
  const [appDataArr, setAppDataArr] = useState([
    { image: require('./assets/portfolio/image1.webp'), link: 'https://play.google.com/store/apps/details?id=com.rks.preschoolbengali' },
    { image: require('./assets/portfolio/image2.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.nursharyalphabets' },
    { image: require('./assets/portfolio/image3.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.nurshuryrhymes' },
    { image: require('./assets/portfolio/image4.webp'), link: 'https://play.google.com/store/apps/details?id=com.rks.mathkids' },
    { image: require('./assets/portfolio/image5.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.preschool' },
    { image: require('./assets/portfolio/image6.webp'), link: 'https://play.google.com/store/apps/details?id=com.ram.wordbook' },
    { image: require('./assets/portfolio/image7.webp'), link: 'https://play.google.com/store/apps/details?id=com.rks.wordbookpicture' },
    { image: require('./assets/portfolio/image8.webp'), link: 'https://play.google.com/store/apps/details?id=com.rks.mehndidesign' },
    { image: require('./assets/portfolio/image9.webp'), link: 'https://play.google.com/store/apps/details?id=com.rks.banglarecipe' },
    { image: require('./assets/portfolio/image10.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.banglacalendar' },
    { image: require('./assets/portfolio/image11.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.audiobanglachora' },
    { image: require('./assets/portfolio/image12.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.generalknowledgebangla' },
    { image: require('./assets/portfolio/image13.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.pinkdiary' },
    { image: require('./assets/portfolio/image14.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.medicinalplanthindi' },
    { image: require('./assets/portfolio/image15.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.bengalirhymeslite' },
    { image: require('./assets/portfolio/image16.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.skincare' },
    { image: require('./assets/portfolio/image17.webp'), link: 'https://play.google.com/store/apps/details?id=com.rks.matchinggame' },
    { image: require('./assets/portfolio/image18.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.inspirationalquotes' },
    { image: require('./assets/portfolio/image19.webp'), link: 'https://play.google.com/store/apps/details?id=com.rks.mathtable' },
    { image: require('./assets/portfolio/image20.webp'), link: 'https://play.google.com/store/apps/details?id=com.rks.preschooltamil' },
    { image: require('./assets/portfolio/image21.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.gachgachrayrogmukti' },
    { image: require('./assets/portfolio/image22.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.mahapurushderbani' },
    { image: require('./assets/portfolio/image23.webp'), link: 'https://play.google.com/store/apps/details?id=com.rksmobile.susthathakarupay' },
    { image: require('./assets/portfolio/image24.webp'), link: 'https://play.google.com/store/apps/details?id=com.babynamesandmeaning' },
    { image: require('./assets/portfolio/image25.webp'), link: 'https://play.google.com/store/apps/details?id=com.goodhabitskids' },
    { image: require('./assets/portfolio/image26.webp'), link: 'https://play.google.com/store/apps/details?id=com.ram.medicinalplant' },
  ])
  const [name, setname]=useState('');
  const [email, setemail]=useState('');
  const [subject, setsubject]=useState('');
  const [message, setmessage]=useState('');
  console.log(getData)
  const updateData = () => {
    setData({ name: "Taru", age: 25 })
    //alert("Test alert")
  }
  useEffect(() => {
    //localStorage.setItem("token", "8765432")
  }, [])

  useEffect(() => {
    if (value) {
      //alert(value)
if(value=='aboutus'){
  const homeElement = document.getElementById('about');
  if (homeElement) {
    homeElement.scrollIntoView({ behavior: 'smooth' });
  }
}else if(value=='blog'){
  const homeElement = document.getElementById('apps');
  if (homeElement) {
    homeElement.scrollIntoView({ behavior: 'smooth' });
  }
}else if(value=='contact'){
  const homeElement = document.getElementById('contact');
    if (homeElement) {
      homeElement.scrollIntoView({ behavior: 'smooth' });
    }
}

      
      
      //console.log('Received value in Home:', value);
      // Perform any actions needed with the new value
    }
  }, [value]);

  const someMethod = () => {
    console.log('Method in Home component called');
    // Your method logic here
  };
  const clickLogin = () => {
    alert('oo')
  }
  return (
    <div className="maindiv">
      <div style={{
        height: 600,
        backgroundImage: `url(${heroBg})`,
        display: 'flex',
        flexDirection: 'row',

      }} id="home">
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          flexDirection: 'column'
        }}>
          <div style={{
            display: "flex",
            alignItems: "center",
          }}>
            <text style={{ color: 'white', fontSize: 40, fontWeight: '300' }}>Welcome to</text>
            <text style={{ color: '#faa615', fontSize: 60, fontWeight: '700', marginLeft: 10 }}>RKS</text>
          </div>

          <text style={{ color: 'white', fontSize: 48, fontWeight: '700',marginTop:-5 }}>Mobile Solution</text>
        </div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}>
          <img src={mobileApp} alt="logo" style={{ height: '50%', width: '50%' }} />
        </div>
      </div>

      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1.5,
          flexDirection: 'column'
        }}>
          <img src={features} alt="logo" style={{ maxHeight: '90%', maxWidth: '100%' }} />

        </div>
        <div id="about" style={{
          display: "flex",
          justifyContent: "center",
          flex: 1,
          flexDirection: 'column',
          paddingRight:'15%'
        }}>
          <text style={{ color: '#21413c', fontSize: 30, fontWeight: '400',marginTop:50 }} onClick={()=>toast("Wow so easy!")}>About Us</text>
          <text style={{ color: '#21413c', fontSize: 20, fontWeight: '300', marginTop: 7 }}>We are R K S Mobile Solutions working from last 6 yearas in Android Mobile Development , Website Development, IOS Apps Development, Website Development. </text>

          <text style={{ color: '#21413c', fontSize: 30, fontWeight: '400', marginTop: 30 }}>Customers Satisfaction</text>
          <text style={{ color: '#848484', fontSize: 20, fontWeight: '300', marginTop: 7 }}>Our first priority is Customer's Satisfaction </text>

          <text style={{ color: '#21413c', fontSize: 30, fontWeight: '400', marginTop: 30 }}>Errorless Programs</text>
          <text style={{ color: '#848484', fontSize: 20, fontWeight: '300', marginTop: 7 }}>All our App and web services is 99.9% erroless according to customer reviews.</text>

          <text style={{ color: '#21413c', fontSize: 30, fontWeight: '400', marginTop: 30 }}>Support</text>
          <text style={{ color: '#848484', fontSize: 20, fontWeight: '300', marginTop: 7 }}>Our team are 24 X 7 times dedicated to our valuable Customers.</text>
        </div>
      </div>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'column',
        backgroundColor: '#f2f9f8'
      }} id="services">
        <text style={{ color: '#21413c', fontSize: 28, fontWeight: '300', marginTop: 50 }}>Services</text>
        <text style={{ color: '#21413c', fontSize: 18, fontWeight: '300', marginTop: 20 }}>Let us discuss our some services</text>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 50
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', alignItems: 'center', marginTop: 20, borderRadius: 20 }}>
            <img src={service} alt="logo" style={{ height: 50, width: 50, marginTop: 50 }} />
            <text style={{ color: '#111', fontSize: 18, fontWeight: '700', marginTop: 20 }}>Services</text>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 30, marginRight: 30, marginBottom: 10 }}>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: 20, }}>Social Media App</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>Education App</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>News Media App</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>Lifestyle App</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>Entertainment Media App</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>Gaming App</pre>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', alignItems: 'center', marginTop: 20, borderRadius: 20, marginLeft: 20 }}>
            <img src={applelogo} alt="logo" style={{ height: 50, width: 50, marginTop: 50 }} />
            <text style={{ color: '#111', fontSize: 18, fontWeight: '700', marginTop: 20 }}>IOS App</text>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 30, marginRight: 30, marginBottom: 10 }}>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: 20, }}>iOS Application Design</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>iOS App Migration</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>iOS Consultation</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>iOS App QA & Testing</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>Support & Maintenance</pre>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', alignItems: 'center', marginTop: 20, borderRadius: 20, marginLeft: 20 }}>
            <img src={www} alt="logo" style={{ height: 50, width: 50, marginTop: 50 }} />
            <text style={{ color: '#111', fontSize: 18, fontWeight: '700', marginTop: 20 }}>Web Development</text>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 30, marginRight: 30, marginBottom: 10 }}>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: 20, }}>CMS based Websites</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>ECommerce Applications</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>API Development</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>Website Optimisation</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>Web Applications</pre>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', alignItems: 'center', marginTop: 20, borderRadius: 20, marginLeft: 20 }}>
            <img src={customer} alt="logo" style={{ height: 50, width: 50, marginTop: 50 }} />
            <text style={{ color: '#111', fontSize: 18, fontWeight: '700', marginTop: 20 }}>Custom Service</text>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 30, marginRight: 30, marginBottom: 10 }}>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: 20, }}>Custom Service and</pre>
              <pre style={{ color: '#21413c', fontSize: 15, fontWeight: '400', marginTop: -3, }}>Support</pre>
            </div>
          </div>
        </div>
      </div>

      {/* --------our favourite mobile apps----- */}

      <div id="apps" style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'column',
        backgroundColor: '#f2f9f8'
      }}>
        <text style={{ color: '#21413c', fontSize: 28, fontWeight: '300', marginTop: 50 }}>Mobile Apps</text>
        <text style={{ color: '#21413c', fontSize: 18, fontWeight: '300', marginTop: 20 }} >Our some reputed Apps on playstore completely free.</text>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 50,
          flexWrap: 'wrap',
          marginLeft: 70,
          marginRight: 70,
          marginBottom: 70,
          alignContent: 'center'
        }}>
          {appDataArr.map((item, index) => {
            return (
              <img src={item.image} alt="logo" style={{ height: 150, width: 150, marginTop: 30, marginLeft: 30,cursor: 'pointer' }} onClick={() => {
                window.open(item.link, '_blank');
              }} />
            );
          })}
        </div>
      </div>

      <div id="contact" style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'column',
      }}>
        <text style={{ color: '#21413c', fontSize: 28, fontWeight: '300', marginTop: 50 }}>Contact</text>
        <text style={{ color: '#21413c', fontSize: 18, fontWeight: '300', marginTop: 20 }}>Our contact details</text>
        <div style={{ display: "flex", flexDirection: 'row', marginTop: 30 }}>
          <div className="card" style={{ display: "flex", flex: 1, flexDirection: 'column' }}>
            <div style={{ display: "flex", flexDirection: 'row' }}>
              <img src={location} alt="logo" style={{ height: 30, width: 30, marginRight: 20 }} />
              <div style={{ display: "flex", flexDirection: 'column' }}>
                <text style={{ color: '#21413c', fontSize: 20, fontWeight: '600' }}>Location:</text>
                <text style={{ marginTop: 10 }}>C1/156 , Noida Sector 55 {'\n'}
                  Noida, Uttar Pradesh - 201301</text>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: 'row', marginTop: 25 }}>
              <img src={email} alt="logo" style={{ height: 30, width: 30, marginRight: 20 }} />
              <div style={{ display: "flex", flexDirection: 'column' }}>
                <text style={{ color: '#21413c', fontSize: 20, fontWeight: '600' }}>Email:</text>
                <text style={{ marginTop: 10 }}>support@rksmobilesolution.in</text>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: 'row', marginTop: 25 }}>
              <img src={call} alt="logo" style={{ height: 30, width: 30, marginRight: 20 }} />
              <div style={{ display: "flex", flexDirection: 'column' }}>
                <text style={{ color: '#21413c', fontSize: 20, fontWeight: '600' }}>Call:</text>
                <text style={{ marginTop: 10 }}>+91 96435 98804</text>
              </div>
            </div>

          </div>

          <div >
            <iframe
              src="https://www.google.com/maps/embed/v1/view?key=AIzaSyDDZm1BF8DOfloDtVsBvdTq3auUbiB91To&center=28.6047335813949,77.34893505187296&zoom=14"
              width="450"
              height="350"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            />
          </div>

        </div>
      </div>
      <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 25, marginBottom: 50 }}>
        <div className="cardOnMessage">
          <form className="formStyle">
            <input className="input-style" type="text" name="name" placeholder="Name" onChange={(event)=>setname(event.target.value)} />
            <input className="input-style" type="text" name="name" placeholder="Email" onChange={(event)=>setemail(event.target.value)}/>
            <input className="input-style" type="text" name="name" placeholder="Subject" onChange={(event)=>setsubject(event.target.value)}/>
            <textarea className="textarea-style" type="text" name="name" placeholder="Message" onChange={(event)=>setmessage(event.target.value)}/>

          </form>
          <div style={{ alignItems: 'flex-end' }}>
            <button style={{ display: "flex", height: 25, backgroundColor: '#1bac91', justifyContent: 'center', alignItems: 'center', height: 50, width: 200, borderRadius: 7, color: '#FFF', fontSize: 16, fontWeight: 'bold', marginTop: 10, cursor:'pointer' }} onClick={()=>{
             if(name || email || subject || message){
              toast("Your query submitted successfully ")
             }else{
              toast("please fills-up required data")
             }

            } }>Save</button>
          </div>
          <ToastContainer />
        </div>
      </div>
      <div style={{ display: 'flex', backgroundColor: '#0c1817', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginLeft: 200, marginBottom: 50 }}>
          <text style={{ color: '#FFF', fontSize: 20, fontWeight: '600', marginTop: 50 }}>Rks Mobile Solution</text>
          <text style={{ color: '#FFF', fontSize: 14, fontWeight: '400', marginTop: 20 }}>C1/156 , Noida Sector 55</text>
          <text style={{ color: '#FFF', fontSize: 14, fontWeight: '400', marginTop: 5 }}>Uttar Pradesh. Pin - 201301</text>
          <text style={{ color: '#FFF', fontSize: 18, fontWeight: '600', marginTop: 25 }}>Phone:<text style={{ color: '#FFF', fontSize: 14, fontWeight: '400', marginTop: 5 }}>+91 964359 8804</text></text>
          <text style={{ color: '#FFF', fontSize: 18, fontWeight: '600', marginTop: 5 }}>Email:<text style={{ color: '#FFF', fontSize: 14, fontWeight: '400', marginTop: 5 }}>info@rksmobilesolutions.in</text></text>
        </div>

        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 50 }} id="google">
          <text style={{ color: '#FFF', fontSize: 18, fontWeight: '600', marginTop: 50 }}>Useful Links </text>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
            <img src={next} alt="logo" style={{ height: 20, width: 20, }} />
            <text style={{ color: '#FFF', fontSize: 14, fontWeight: '500',cursor:'pointer'  }} onClick={()=>{
              const homeElement = document.getElementById('home');
              if (homeElement) {
                homeElement.scrollIntoView({ behavior: 'smooth' });
              }
            }}>Home</text>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
            <img src={next} alt="logo" style={{ height: 20, width: 20, }} />
            <text style={{ color: '#FFF', fontSize: 14, fontWeight: '500',cursor:'pointer' }} onClick={()=>{
              const homeElement = document.getElementById('about');
              if (homeElement) {
                homeElement.scrollIntoView({ behavior: 'smooth' });
              }
            }}>About us</text>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
            <img src={next} alt="logo" style={{ height: 20, width: 20, }} />
            <text style={{ color: '#FFF', fontSize: 14, fontWeight: '500',cursor:'pointer' }} onClick={()=>{
              const homeElement = document.getElementById('services');
              if (homeElement) {
                homeElement.scrollIntoView({ behavior: 'smooth' });
              }
            }}>Services</text>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
            <img src={next} alt="logo" style={{ height: 20, width: 20, }} />
            <text style={{ color: '#FFF', fontSize: 14, fontWeight: '500',cursor:'pointer' }}>Terms of service</text>
          </div>
        </div>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 50 }}>
          <text style={{ color: '#FFF', fontSize: 18, fontWeight: '600', marginTop: 50 }} onClick={()=>{
              const homeElement = document.getElementById('home');
              if (homeElement) {
                homeElement.scrollIntoView({ behavior: 'smooth' });
              }
            }}>Our Services</text>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
            <img src={next} alt="logo" style={{ height: 20, width: 20, }} />
            <text style={{ color: '#FFF', fontSize: 14, fontWeight: '500',cursor:'pointer' }} onClick={()=>{
              const homeElement = document.getElementById('apps');
              if (homeElement) {
                homeElement.scrollIntoView({ behavior: 'smooth' });
              }
            }}>Android Mobile App</text>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
            <img src={next} alt="logo" style={{ height: 20, width: 20, }} />
            <text style={{ color: '#FFF', fontSize: 14, fontWeight: '500',cursor:'pointer' }}>IOS App</text>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
            <img src={next} alt="logo" style={{ height: 20, width: 20, }} />
            <text style={{ color: '#FFF', fontSize: 14, fontWeight: '500',cursor:'pointer' }}>Web Development</text>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
            <img src={next} alt="logo" style={{ height: 20, width: 20, }} />
            <text style={{ color: '#FFF', fontSize: 14, fontWeight: '500', cursor:'pointer'}}>Online Applications</text>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', backgroundColor: '#000', flexDirection: 'column', alignItems: 'center' }}>
        <text style={{ color: '#FFF', fontSize: 14, fontWeight: '400', margin: 30 }}>© Copyright <text style={{ color: '#FFF', fontSize: 18, fontWeight: '600', marginTop: 25 }}>RKS Mobile Solutions.</text>All Rights Reserved</text>

      </div>


    </div>
  );
};

export default Home;


import React, { createContext, useState } from "react";
const UserDataContext = createContext(undefined);


const UserContext = ({children}) => {
    const [userDetails, setUserDetails] = useState({name: "anup", age : 60})
  return (
    <UserDataContext.Provider value={{getData: userDetails, setData: setUserDetails}} >
      {children}
    </UserDataContext.Provider>
  )
}
export {UserDataContext}
export default UserContext
